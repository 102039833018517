import React, { useEffect, useState } from 'react'
import Card from '../../modules/card';
import { RootState } from '../../store/store';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { TRandomTimeSlot } from '../../types/timeSlotTypes';
import WidgetLoader from '../../components/loader';
import { useLazyFreeSlotsByPractitionerQuery, useLazyGetRandomTimeSlotQuery } from '../../api/bookingApiSlice';
import { randomTimeSlotData } from '../bookingDataSlice';
import RandomTimeSlot from './randomTimeSlot';
import Moment from 'react-moment';
import { DatePicker } from '@progress/kendo-react-dateinputs';
import { Label } from '@progress/kendo-react-labels';
import moment from 'moment';
import { Message } from '../../localization/Message';
import DateDrawer from '../../components/formattedDates/DateDrawer';
import TimeDrawer from '../../components/formattedDates/TimeDrawer';

const dateformat = (selectedLang: "en_us" | "de_ch") => {
    let fromat;
    if (selectedLang === "en_us") fromat = "dd/MM/yyyy";
    else if (selectedLang === "de_ch") fromat = "dd.MM.yyyy";
    return fromat;
};

const placeHolderDrawer = (selectedLang: "en_us" | "de_ch") => {
    let placeHolder;
    if (selectedLang === 'en_us') placeHolder = "DD/MM/YYYY";
    else if (selectedLang === 'de_ch') placeHolder = "TT.MM.JJJJ";
    return placeHolder;
};


const TreatmentTypes = () => {

    const [step, setStep] = useState<number>(0);
    const serviceId = useAppSelector((state: RootState) => state.bookingData.treatment?.id);
    const practitionerId = useAppSelector((state: RootState) => state.bookingData.doctor?.id);
    const practitioner = useAppSelector((state: RootState) => state.bookingData.doctor);
    const clientId = useAppSelector((state: RootState) => state.bookingData.clientId);
    const practitionerList = useAppSelector((state: RootState) => state.bookingData.doctorList);
    const bookingStep = useAppSelector((state: RootState) => state.bookingData.step);
    const selectedLang = useAppSelector((state: RootState) => state.bookingData.selectedLng);
    const [selectItem, setSelectItem] = useState<TRandomTimeSlot | null>(null);
    const [toggleSelector, setToggleSelector] = useState<boolean>(false);
    const [date, setDate] = useState<Date | null>(null);
    const currentDate = new Date();
    const [triggerRandomTimeSlots, { isLoading, isFetching, data: randomTimeSlotList }] = useLazyGetRandomTimeSlotQuery();
    const [triggerPractitionerTimeSlots, { isLoading: isFreeTimeSlotLoading, isFetching: isFreeTimeSlotFetching, data: freeTimeSlotList }] = useLazyFreeSlotsByPractitionerQuery();
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (selectItem) {
            setToggleSelector(prev => !prev)
        } else if (isLoading || isFetching) {
            setToggleSelector(false)
        } else {
            setToggleSelector(true)
        }
    }, [selectItem, isLoading, isFetching]);


    useEffect(() => {
        if (serviceId && clientId && practitionerId) {
            setDate(null);
            triggerRandomTimeSlots({ clientId, practitionerId, serviceId, step: 0 })
        }
    }, [step, practitionerId]);

    useEffect(() => {
        if (bookingStep == 3) {
            setDate(null);
            setSelectItem(null);
        }
    }, [bookingStep]);

    useEffect(() => {
        if (date) {
            dispatch(randomTimeSlotData({ step: 3, randomTimeSlot: null, slotDoctor: null }));
            if (serviceId && clientId && practitionerId && date)
                triggerPractitionerTimeSlots({
                    clientId,
                    practitionerId,
                    serviceId,
                    date: moment(date).format('YYYY-MM-DD')
                })
        };
    }, [date])

    const onSelectItem = (item: TRandomTimeSlot, currentItem: TRandomTimeSlot | null) => {
        if (currentItem?.Id == item.Id) {
            setSelectItem(null);
            dispatch(randomTimeSlotData({ step: 3, randomTimeSlot: null, slotDoctor: null }));
        } else {
            const selectedPractitioner = practitionerList?.find((practitioner) => practitioner.id == item?.PractitionerId)
            setSelectItem(item);
            dispatch(randomTimeSlotData({ step: 4, randomTimeSlot: item, slotDoctor: selectedPractitioner ? selectedPractitioner : practitioner }));
        }
    }

    const onShowMoreClick = () => {
        setStep(prev => prev + 1);
    }

    const titleRender = () => {
        return <div className='treatment-title-wrapper'>
            {(isLoading || isFetching || isFreeTimeSlotLoading || isFreeTimeSlotFetching) && <div className='w-8'><WidgetLoader /></div>}
            <div className="treatment-title">
                {selectItem ?
                    <div className='list-item-container'>
                        <div className='selected'></div>
                        <DateDrawer date={selectItem.StartDate} />
                        <span className='pl-2'>
                            <TimeDrawer date={selectItem.StartDate} /> - <TimeDrawer date={selectItem.EndDate} />
                        </span>
                    </div>
                    :
                    <Message messageKey='booking_widget_consultant_availability' defaultMessage='Treatment Availability' />
                }
            </div>
        </div>
    };

    const dateSelecter = ({ value }: any) => {
        setDate(value);
    };
    const clearSelectedDate = () => {
        setDate(null);
        dispatch(randomTimeSlotData({ step: 3, randomTimeSlot: null, slotDoctor: null }));
    };

    const timeSlotItemRender = () => {

        if (isLoading || isFetching || isFreeTimeSlotLoading || isFreeTimeSlotFetching) return <div className="mx-2 myt-2 mb-6 ">
            <Message messageKey='booking_widget_time_slot_loading' defaultMessage='Wait time slots are loading...!' />
        </div>
        if (date) {
            return <>
                <div className="treatment-list flex flex-row gap-2 mx-2 my-4 items-center justify-center">
                    {(freeTimeSlotList?.Data && freeTimeSlotList?.Data.length > 0) ? freeTimeSlotList.Data.map(((timeSlot: TRandomTimeSlot, index) =>
                        <RandomTimeSlot key={timeSlot.Id} onClick={() => onSelectItem(timeSlot, selectItem)} timeSlot={timeSlot} selectedItemId={selectItem?.Id} free />
                    )) : <div className='flex flex-col gap-3 mb-3'>
                        <Message messageKey='booking_widget_no_available_timeSlots_today' defaultMessage={`Haven't any Available Time Slots for Today..!`} />
                        <Message messageKey='booking_widget_please_pick_another_date' defaultMessage='Please Pick another Date' />
                    </div>}
                </div>
            </>
        } else {
            return <>
                <div className="treatment-list flex flex-row gap-2 mx-2 my-4 items-center justify-center">
                    {(randomTimeSlotList?.Data && randomTimeSlotList?.Data.length > 0) ? randomTimeSlotList.Data.map(((timeSlot: TRandomTimeSlot, index) =>
                        <RandomTimeSlot key={timeSlot.Id} onClick={() => onSelectItem(timeSlot, selectItem)} timeSlot={timeSlot} selectedItemId={selectItem?.Id} />
                    )) : <Message messageKey={'booking_widget_no_available_rendom_timeSlots'} defaultMessage={`Haven't any Random Time Slots.`} />}
                </div>
                <div className="flex p-2">
                    <button type="button" className="
                    time-slot-button py-2.5 px-5 font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 
                    hover:bg-gray-100 hover:#fff focus:z-10 focus:ring-4 focus:ring-gray-100 flex-grow"
                        onClick={onShowMoreClick}
                    ><Message messageKey={'booking_widget_ show_more'} defaultMessage={'Show More...'} /></button>
                </div>
            </>
        }
    }

    const contentRender = () => {
        return (
            <>
                <div className="date-timeslot mx-2 my-4 flex flex-col gap-2 w-100 sm:w-[50%]">
                    <Label><Message messageKey='booking_widget_select_custom_date' defaultMessage='Select Custom Date' /></Label>
                    <div className='flex flex-1 w-[100%] items-center'>
                        <DatePicker
                            className='time-slot-selector'

                            onChange={dateSelecter}
                            format={dateformat(selectedLang)}
                            placeholder={placeHolderDrawer(selectedLang)}
                            value={date}
                            min={currentDate}
                        />
                        {date && <span className="k-icon k-i-x k-font-icon" style={{ fontSize: "20px" }} onClick={clearSelectedDate} />}
                    </div>
                </div>
                {timeSlotItemRender()}
            </>
        );
    };

    const setToggle = () => {
        setToggleSelector(prev => !prev)
    };

    return (
        <div className="treatment-container">
            <div className="treatment-title pb-3"><Message messageKey='booking_widget_consultant_availability' defaultMessage='Treatment Availability' /> </div>
            <Card title={titleRender()} content={contentRender()} toggle={toggleSelector} setToggle={setToggle} />
        </div>
    )
}

export default TreatmentTypes

