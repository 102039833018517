import { Stepper, StepperChangeEvent } from "@progress/kendo-react-layout";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { RootState } from "../../store/store";
import './index.scss';
import { progressStepChange } from "../bookingDataSlice";

const steps = [
    { icon: "k-i-plus-outline" },
    { icon: "k-i-user" },
    { icon: "k-i-calendar-date" },
    { icon: "k-i-dictionary-add" },
    { icon: "k-i-track-changes-accept" },
];

const StepperComponent = () => {
    const [stepValue, setStepValue] = useState<number>(0);
    const step = useAppSelector((state: RootState) => state.bookingData.step);
    const dispatch = useAppDispatch();

    useEffect(() => {
        setStepValue(step - 1)
    }, [step]);

    const onStepperClick = ({ value }: StepperChangeEvent) => {
        if (step - 1 >= value)
            dispatch(progressStepChange(value))
    }

    return (
        <Stepper items={steps} value={stepValue} disabled={stepValue >= 4} onChange={onStepperClick} />
    );
};

export default StepperComponent;