import React from 'react'
import { TRandomTimeSlot } from '../../types/timeSlotTypes';
import Moment from 'react-moment';
import './index.scss'
import DateDrawer from '../../components/formattedDates/DateDrawer';
import TimeDrawer from '../../components/formattedDates/TimeDrawer';

interface TRandomTimeSlotData {
    timeSlot: TRandomTimeSlot,
    onClick: () => void,
    selectedItemId: number| undefined
    free?: boolean
}

const RandomTimeSlot = ({ timeSlot, onClick, selectedItemId, free }: TRandomTimeSlotData) => {
    return (
        <div key={timeSlot.Id} onClick={onClick} className={`p-2 time-slot rounded cursor-pointer ${selectedItemId== timeSlot.Id ? 'time-selected' : ''} ${!free ? 'min-w-[270px]' : 'min-w-[188px]'}`}>
            {!free && <DateDrawer  date={timeSlot.StartDate}/>}
            <span className='pl-2'>
                <TimeDrawer date={timeSlot.StartDate}/> - <TimeDrawer date={timeSlot.EndDate}/>
            </span>
        </div>
    )
}

export default RandomTimeSlot