export const USER_ROLES = Object.freeze({
    DENTIST : 1,
    PATIENT : 2,
    SMYLOR_BOT : 3,
    PARTICIPANT : 4,
    ADMIN : 5,
});

export enum LOGIN_MODULE_ROLES {
    PATIENT,
    DENTIST,
};

export const StatusCode = Object.freeze({
    success: 200,
    unAuthenticated: 401,
    invalid: 400,
    notVerified: 406,
    serverError: 500,
    alreadyExist: 300,
    supportSignUp: 101
});


