import React, { useEffect, useState } from "react";
// import { useNavigate } from "react-router-dom";
import { useLocalization } from "@progress/kendo-react-intl";
import PatientTabView from "./patient";
import { StatusCode, USER_ROLES } from "../../core/constant";
// import { faTimesCircle } from "@fortawesome/fontawesome-svg-core";
import './index.css'
import { Modal, Popover } from "antd";
import EerrorMessageRender from "./errorMessageRender";
import { TGetUserDetails } from "../../types/profile.types";
import { useLazySocialSignInQuery, useUserSignInMutation } from "../../api/loginApiSlice";
import { logoutUser, sessionCreator, setSession } from "../../core/utils";


const LoginMenu = ({
    showMenu,
    setShowMenu,
    supportSignUp,
    code
}: {
    showMenu: boolean;
    setShowMenu: (value: boolean) => void;
    supportSignUp?: any;
    code: string | null;
}) => {
    const localization = useLocalization();
    const [show, setShow] = useState<boolean>(false);
    const [loadingLogin, setLoadingLogin] = useState<boolean>(false);
    const [statusCode, setStatusCode] = useState<number | null>(null);
    const [triggerGetUser, { isLoading }] = useUserSignInMutation();
    const [triggerSocialGetUser, { isLoading: isSocialLoading, isFetching, data: logindata }] = useLazySocialSignInQuery();

    const handleSubmit = (e: TGetUserDetails) => {
        triggerGetUser(e).unwrap()
            .then(res => { responseHandler(res) })
            .catch(err => { setStatusCode(err.status) });
    };

    useEffect(() => {
        setShow(showMenu);
    }, [showMenu]);

    useEffect(() => {
        document.body.style.overflow = show ? "hidden" : "unset";
    }, [show]);

    useEffect(() => {
        if (statusCode === StatusCode.success || statusCode === StatusCode.notVerified) {
            setShow(false);
        }
    }, [statusCode,]);

    useEffect(() => {
        if (code) {
            triggerSocialGetUser({ code: code.split("#")[0], url: process.env.REACT_APP_WEB_BOOKING_URL })
        }
    }, [code])

    useEffect(() => {
        if (logindata) {
            setStatusCode(200);
            const converetedRes = sessionCreator(logindata);
            converetedRes && setSession(converetedRes);
        }
    }, [logindata])

    const responseHandler = (res: any) => {
        if (res) {
            setStatusCode(200);
            const converetedRes = sessionCreator(res);
            converetedRes && setSession(converetedRes);
            setShow(false);
            setShowMenu(false);
        } else setStatusCode(StatusCode.serverError)
    }

    //   session?.userId
    const onClose = (e: any) => {
        setShow(false);
        setShowMenu(false);
    };

    const logoRender = () => {
        return (
            <div className="bg-smylor-blue-dark-pro p-8 relative rounded-t-lg">
                <img className="h-[48px] md:h-[64px]"
                    src={"https://zahncal.com/media/5gcntwcz/smylor-logo-final-v2_c.png"}
                    alt={"logo"}
                />
                <div 
                className="bg-white rounded-full p-1 absolute cursor-pointer right-2 top-2 w-[20px] h-[20px] flex items-center justify-center"
                onClick={onClose}
                >
                    <span className="k-icon k-font-icon k-i-x !text-sm"></span>
                </div>
            </div>
        );
    };

    return (
        <>
            {(
                <Modal open={show} className="modal-custom" closeIcon={false} footer={false}>
                    <div
                        className={isLoading || loadingLogin ? "k-overlay" : ""}
                    ></div>
                    {logoRender()}
                    <EerrorMessageRender code={supportSignUp == 'true' ? 101 : statusCode} />
                    {/* 
                    <TabStrip
                        className="login-tab-strip login-tab-strip-admin"
                        selected={0}
                    >
                    </TabStrip> */}
                    <div className="text-lg flex items-center font-normal justify-center p-4">
                        {localization.toLanguageString(
                            "login_module_patient",
                            "Patient"
                        )}
                    </div>
                    <div className="login-border"></div>
                    <PatientTabView
                        localization={localization}
                        setLoadingLogin={setLoadingLogin}
                        loggedUserFetching={false}
                        selected={USER_ROLES.PATIENT}
                        handleSubmit={handleSubmit}
                    />
                </Modal >
            )}
        </>
    );
};

export default LoginMenu;
