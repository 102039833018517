import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReauth } from './interceptorsLoginSlice';
import { TAppointmentCreate,  } from '../types/appointmentTypes';
import { TGetUserDetails, TUserProfile } from '../types/profile.types';

export const loginApiSlice = createApi({
  reducerPath: 'loginApi',
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    userSignIn: builder.mutation<TUserProfile, TGetUserDetails>({
      query: (data) => ({
        url: 'user/patient/signin',
        method: 'POST',
        body: data,
      }),
    }),
    socialSignIn: builder.query<any, any>({
        query: ({ code, url }) => ({
          url: `user/social/signin/${code}`,
          params: { url},
          method: 'GET'
        }),
      }),
  }),
});

export const {
    useUserSignInMutation,
    useLazySocialSignInQuery
} = loginApiSlice