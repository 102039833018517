import React, { useEffect, useState } from 'react'
import Card from '../../modules/card';
import { default as userDefaultLogo } from '../../components/header/avatar.png';
import { useLazyGetDentistDataQuery } from '../../api/bookingApiSlice';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { RootState } from '../../store/store';
import WidgetLoader from '../../components/loader';
import { doctorData } from '../bookingDataSlice';
import { TDentistDetail } from '../../types/treatmentTypes';
import { Message } from '../../localization/Message';

const DoctorList = () => {

    const serviceId = useAppSelector((state: RootState) => state.bookingData.serviceId);
    const clientId = useAppSelector((state: RootState) => state.bookingData.clientId);
    const urlDoctor = useAppSelector((state: RootState) => state.bookingData.doctorId);
    const doctor = useAppSelector((state: RootState) => state.bookingData.doctor);
    const [selectItem, setSelectItem] = useState<TDentistDetail | null>(doctor);
    const [toggleSelector, setToggleSelector] = useState<boolean>(false);
    const [triggerDoctorList, { isLoading, isFetching, data: doctorTypeData }] = useLazyGetDentistDataQuery();
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (urlDoctor) {
            setToggleSelector(false);
        } else if (selectItem) {
            setToggleSelector(prev => !prev);
        } else if (isFetching || isLoading) {
            setToggleSelector(false);
        } else {
            setToggleSelector(true);
        }
    }, [JSON.stringify(selectItem), isLoading, isFetching]);

    const titleRender = () => {
        return <div className='treatment-title-wrapper'>
            {(isLoading || isFetching) && <div className='w-8'><WidgetLoader /></div>}
            <div className="treatment-title">
                {selectItem ?
                    <div className='list-item-container'>
                        <div className='selected'></div>
                        <img className='min-avator' src={selectItem.image ? selectItem.image : userDefaultLogo} />
                        {selectItem.fullName}
                    </div>
                    :
                    <Message messageKey='booking_widget_select_practitioner' defaultMessage='Choose your doctor or hygienist' />
                }
            </div>
        </div>
    };

    useEffect(() => {
        (serviceId && clientId) && triggerDoctorList({ clientId, serviceId });
    }, [serviceId]);

    useEffect(() => {
        doctor ? setSelectItem(doctor) : setSelectItem(null);
    }, [doctor])

    useEffect(() => {
        if (doctorTypeData?.Data && urlDoctor) {
            const selectedDoctor = doctorTypeData?.Data.find((doctor) => doctor.id == urlDoctor)
            if (selectedDoctor) {
                setSelectItem(selectedDoctor);
                dispatch(doctorData({ step: 3, doctor: selectedDoctor, doctorList: doctorTypeData?.Data }));
            }
        }
    }, [JSON.stringify(doctorTypeData?.Data)])

    const onSelectItem = (item: TDentistDetail, currentItem: TDentistDetail | null) => {
        if (currentItem?.id == item.id) {
            setSelectItem(null);
            dispatch(doctorData({ step: 2, doctor: null, doctorList: null }));
        } else {
            setSelectItem(item);
            dispatch(doctorData({ step: 3, doctor: item, doctorList: doctorTypeData?.Data }));
        }
    }

    const contentRender = () => {
        return (
            <div className="treatment-list">
                {doctorTypeData?.Data && doctorTypeData.Data.map(((doctor: TDentistDetail, index) => <div key={index} className={`treatment text-left ${selectItem?.id == doctor.id ? 'item-selected' : ''}`}>
                    <div onClick={() => onSelectItem(doctor, selectItem)} className='list-item-container'>
                        {selectItem?.id == doctor.id ? <div className='selected'></div> : ''}
                        <img className='min-avator' src={doctor.image ? doctor.image : userDefaultLogo} />
                        {doctor.fullName}
                    </div>
                </div>))}
            </div>
        );
    };

    const setToggle = () => {
        setToggleSelector(prev => !prev)
    };

    return (
        <div className="treatment-container">
            <div className="treatment-title pb-3">
                <Message messageKey='booking_widget_select_practitioner' defaultMessage='Choose your doctor or hygienist' />
            </div>
            <Card title={titleRender()} content={contentRender()} toggle={toggleSelector} setToggle={setToggle} />
        </div>
    )
}

export default DoctorList;

