import moment from "moment";
// import { maskedPhoneNumberCoverter } from "../utils/maskedPhoneNumberCoverter";
import { formatPhoneNumberIntl, isPossiblePhoneNumber } from "react-phone-number-input";

const emailRegex: RegExp = new RegExp(/\S+@\S+\.\S+/);
const phoneNumberRegex: RegExp = new RegExp(/^[0-9 ()+-]+$/);
const mobileNumberRegex: RegExp = /^\(\+\d{2}\)\s\d{3}-\d{7}$/;
const numberRegex: RegExp = new RegExp(/^[1-9]\d*(\.\d+)?$/);
const nameValRegex: RegExp = new RegExp(/^[a-zA-Z0-9äöüßÄÖÜ]+$/);

const emailValidator: any = (value: string) => {
    return (emailRegex.test(value) ? "" : "booking_widget_validation_valid_email")
};

const InputValidator: any = (value: string) => {
    if (value) {
        if (value?.length < 3) {
            return "booking_widget_validation_textInput_characters"
        } else {
            if (nameValRegex.test(value)) return ""
            else return "booking_widget_validation_german_characters"
        }
    } else {
        return "booking_widget_validation_fill_this_field";
    }
}

const phoneNumberValidator: any = (value: string) => {
    if (phoneNumberRegex.test(value)) {
        if (value.length < 10) {
            return "booking_widget_validation_phoneNumber_characters"
        } else if (value.length > 15) {
            return "booking_widget_validation_phoneNumber_characters_max"
        }
    } else return "booking_widget_validation_valid_phoneNumber"
}

const dropdownValidator: any = (value: any) => {
    return value ? "" : "booking_widget_validation_dropdwon_gender";
}

const dateOfBirthValidator: any = (value: any) => {
    let different = null;
    const currentDate = moment(new Date().toISOString()).format("YYYY-MM-DD");
    const selectedDate = value ? moment(new Date(value).toISOString()).format("YYYY-MM-DD") : '';
    different = moment(currentDate).diff(moment(selectedDate));

    return value ? different > 0 ? "" : 'booking_widget_validation_date_of_birth_date_not_valid'
        : "booking_widget_validation_date_of_birth_empty";
}

const checkBoxValidator: any = (value: any) => {
    return value ? "" : "booking_widget_validation_accept_privacy_terms";
}

const checkBoxNormalValidator: any = (value: any) => {
    return value ? "" : "booking_widget_validation_check_the_box";
}

const isDateValidValidator = (date: Date, format: string): boolean => {
    let returnValue: boolean = false;
    const parsedDate = moment(date, format);
    if (parsedDate.isValid()) {
        if (parsedDate.year() >= moment(new Date()).year()) {
            returnValue = true;
        }
    }
    return returnValue;
};

const RadioGroupValidator = (value: any) => { return !value ? "booking_widget_validation_select_payment_type" : ""; };

// const MaskedPhoneNumberValidator = (value: any) => {

//     if (value) {
//         const maskedConvertedInput: string = maskedPhoneNumberCoverter(value);
//         if (maskedConvertedInput && maskedConvertedInput.length >= 12) return ""
//         else return 'booking_widget_validation_valid_mobileNumber'
//     } else return 'booking_widget_validation_valid_mobileNumber';
// };

// const MaskedLandNumberValidator = (value: any) => {

//     if (value) {
//         const maskedConvertedInput: string = maskedPhoneNumberCoverter(value);
//         if (maskedConvertedInput.length === 1) return ""
//         else if (maskedConvertedInput.length >= 12) return ""
//         else return 'booking_widget_validation_valid_mobileNumber'
//     } else return "";
// };

// const smylorPhoneNumberValidator = (value: string) => {

//     if (value && isPossiblePhoneNumber(value)) {
//       if (value.length < 12) return "validation_phone_number_must_have_least_10_characters";
//       else if (value.length > 16) return "validation_phone_number_must_have_max_13_characters"
//     } else return "validation_please_enter_a_valid_phone_number"
//   };

const smylorPhoneNumberValidator = (value: string) => {
    if (value) {
        if (!formatPhoneNumberIntl(value)) return "validation_phone_number_select_country_code"
        else if (value.length < 12) return "validation_phone_number_must_have_least_10_characters";
        else if (value.length > 16) return "validation_phone_number_must_have_max_13_characters";
        else return ""
    } else return "validation_please_enter_a_valid_phone_number"
};

const valueValidator = (value: string) => {
    return value ? "" : "validation_fill_this_field";
  };

export {
    emailValidator,
    InputValidator,
    phoneNumberValidator,
    dropdownValidator,
    checkBoxValidator,
    dateOfBirthValidator,
    isDateValidValidator,
    RadioGroupValidator,
    // MaskedPhoneNumberValidator,
    // MaskedLandNumberValidator,
    checkBoxNormalValidator,
    smylorPhoneNumberValidator,
    valueValidator
}