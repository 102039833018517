import React from "react";
import { Message } from "../../localization/Message";
import { StatusCode } from "../../core/constant";



const EerrorMessageRender = ({
    code,
}: {
    code: number | null
}) => {

    switch (code) {
        case StatusCode.success:
            return <></>;

        case StatusCode.unAuthenticated:
            return (
                <div className="login-error">
                    <Message
                        messageKey={"login_module_wrong_email_password"}
                        defaultMessage={"WRONG EMAIL OR PASSWORD."}
                    />
                </div>
            );
        case StatusCode.serverError:
            return (
                <div className="login-error">
                    <Message
                        messageKey={"login_module_fetching_error"}
                        defaultMessage={"ERROR.! PLEASE RETRY AFTER RELOAD"}
                    />
                </div>
            );
        case StatusCode.alreadyExist:
            return (
                <div className="login-error">
                    <Message
                        messageKey={"login_module_user_exist"}
                        defaultMessage={"USER TRYING TO LOGGING IS EXIST. ENTER YOUR EMAIL AND PASSWORD TO LOGIN"}
                    />
                </div>
            );
        case StatusCode.supportSignUp:
            return (
                <div className="not-vrfied">
                    <Message
                        messageKey={"login_module_user_verfied_success"}
                        defaultMessage={"YOUR EMAIL WAS VERIFIED. SIGN UP TO CONTINUE.."}
                    />
                </div>
            );
        default:
            return <></>;
    }
};

export default EerrorMessageRender;
