import * as React from "react";

import { Popup, PopupProps } from "@progress/kendo-react-popup";
import { Calendar, CalendarProps } from "@progress/kendo-react-dateinputs";

export const CustomPopup = (props: PopupProps) => {
  return (
    <Popup
      {...props}
      anchorAlign={{
        horizontal: "left",
        vertical: "bottom",
      }}
      popupAlign={{
        horizontal: "left",
        vertical: "top",
      }}
    />
  );
};

export const CustomPickerWrap = (props : CalendarProps) => {
  return <Calendar  {...props} defaultActiveView="year" />
};