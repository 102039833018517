import { faSquareFacebook } from '@fortawesome/free-brands-svg-icons'
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useLocalization } from '@progress/kendo-react-intl';
import { Button } from 'antd';
import { WidgetSmallLoader } from '../../loader';
import { Google } from '../../img';


const SocialMediaLogin = ({ setLoadingLogin, overlayLoading = false }: { setLoadingLogin: Function, overlayLoading: boolean }) => {
    const [loading, setLoading] = useState<{ facebbok: boolean, google: boolean }>({ facebbok: false, google: false });
    const localization = useLocalization();

    const onGoogleClick = (e: any) => {
        overlayLoading && setLoadingLogin(true);
        setLoading(prev => ({ ...prev, google: true }));
        window.open(`${process.env.REACT_APP_SOCIAL_URL}authorize?response_type=code&client_id=${process.env.REACT_APP_SOCIAL_CODEL}&redirect_uri=${window.location.href}&scope=openid%20profile%20email&connection=google-oauth2`, "_self");
    };
    const onFacebookClick = (e: any) => {
        overlayLoading && setLoadingLogin(true);
        setLoading(prev => ({ ...prev, facebbok: true }));
        window.open(`${process.env.REACT_APP_SOCIAL_URL}authorize?response_type=code&client_id=${process.env.REACT_APP_SOCIAL_CODE}&redirect_uri=${window.location.href}&scope=openid%20profile%20email&connection=facebook`, "_self");
    };
    return (
        <div className='flex flex-col gap-4 mt-2'>
            <Button onClick={onFacebookClick}
                className='social-btn flex flex-row h-[40px] hover:!border-smylor-blue-mana-c hover:!bg-smylor-blue-mana-f hover:!text-smylor-blue-dark-pro'
            // overlayLoading={overlayLoading}
            >
                <FontAwesomeIcon
                    icon={faSquareFacebook as IconProp}
                    className="facebook-icon h-[28px] text-smylor-blue-dark-pro"
                />
                <div className='flex grow justify-center font-medium text-xs'>
                    {localization.toLanguageString(
                        "login_module_sign_in_facebook",
                        "Sign in with Facebook"
                    )}
                </div>
                {loading.facebbok && <WidgetSmallLoader />}
            </Button>
            <Button onClick={onGoogleClick}
                className='social-btn flex flex-row h-[40px] hover:!border-smylor-blue-mana-c hover:!bg-smylor-blue-mana-f hover:!text-smylor-blue-dark-pro'
            // overlayLoading={overlayLoading}
            >
                <img src={Google} className="google-icon" />
                <div className='flex grow justify-center font-medium text-xs'>
                    {localization.toLanguageString(
                        "login_module_sign_in_google",
                        "Sign in with Google"
                    )}
                </div>
                {loading.google && <WidgetSmallLoader />}
            </Button>
        </div>
    )
}

export default SocialMediaLogin