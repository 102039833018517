import React from 'react'
type WidgetLoaderProps = {
    lg?: boolean
}

const WidgetLoader = ({ lg }: WidgetLoaderProps) => {
    return (
        <div
            style={{ color: 'rgb(29, 79, 145)' }}
            className={`
                ${lg ? '!h-12' : 'h-6'}
                ${lg ? "!w-12" : "w-6"}
                md:w-8 md:h-8
                inline-block animate-spin rounded-full border-4 border-solid border-current 
                border-r-transparent align-[-0.125em] text-primary 
                motion-reduce:animate-[spin_1.5s_linear_infinite]`}
            role="status">
            <span
                className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]"
            >Loading...</span>
        </div>
    )
}

export default WidgetLoader;

const WidgetSmallLoader = () => {
    return (
        <div
            style={{ color: 'rgb(29, 79, 145)' }}
            className={`
                w-6 h-6
                inline-block animate-spin rounded-full border-4 border-solid border-current 
                border-r-transparent align-[-0.125em] text-primary 
                motion-reduce:animate-[spin_1.5s_linear_infinite]`}
            role="status">
            <span
                className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]"
            >Loading...</span>
        </div>
    )
}

export { WidgetSmallLoader };