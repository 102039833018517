import { loadMessages, LocalizationProvider } from '@progress/kendo-react-intl';
import './App.css';
import { Header } from './components';
import BookingWidget from './views';
import { useEffect } from 'react';
import { useLazyGetTranslationsQuery } from './api/bookingApiSlice';
import { useAppDispatch, useAppSelector } from './store/hooks';
import { RootState } from './store/store';
import WidgetLoader from './components/loader';
import { getCookiesValue } from './core/utils';
import { chageSelectedLng, setClientId, setDoctorId, setServiceId, setUser } from './views/bookingDataSlice';
import Cookies from 'js-cookie';

function App() {
  const dispatch = useAppDispatch();
  const queryParams = new URLSearchParams(window.location.search);
  const clientId = queryParams.get('clientId');
  const doctor = queryParams.get('doctor');
  const service = queryParams.get('service');
  const [triggerGetTreatmentTypes, { isLoading, data: translationData }] = useLazyGetTranslationsQuery();
  const selectedLng = useAppSelector((state: RootState) => state.bookingData.selectedLng);
  const globleLang: string | undefined | "en" = getCookiesValue('globleLang');
  const strSession = Cookies.get('u-in');
  const user = strSession ? JSON.parse(strSession) : null;
  
  useEffect(() => {
    return () => {
      console.log('v-1.1.0')
    };
  }, []);

  useEffect(() => {
    clientId && dispatch(setClientId(parseInt(clientId)));
    service && dispatch(setServiceId(parseInt(service)));
    doctor && dispatch(setDoctorId(parseInt(doctor)));
  }, [clientId, doctor, service])

  useEffect(() => {
    const logoutChannel = new BroadcastChannel('logoutChannel');
    const handleLogoutEvent = () => {
      window.open(window.location.href, '_self');
    };

    logoutChannel.addEventListener('message', handleLogoutEvent);
    triggerGetTreatmentTypes();
    user && dispatch(setUser(user));

    if (globleLang) {
      if (globleLang === "En_Us")
        dispatch(chageSelectedLng('en_us'));
      else
        dispatch(chageSelectedLng('de_ch'));
    }
    return () => {
      logoutChannel.removeEventListener('message', handleLogoutEvent);
      logoutChannel.close();
    };
  }, []);

  useEffect(() => {
    if (translationData?.Data) {
      (Object.keys(translationData.Data) as Array<"de_ch" | "en_us">).forEach((key) => {
        loadMessages({ ...translationData.Data[key], today: "hoy" }, key);
      });
    }
  }, [JSON.stringify(translationData?.Data)]);

  if (isLoading) {
    return <div id="loader" className='w-[100vw] h-[100vh] flex items-center justify-center'>
      <WidgetLoader lg />
    </div>
  };

  return (<>
    {<div className="App">
      <LocalizationProvider language={selectedLng}>
        <Header />
        <BookingWidget />
      </LocalizationProvider>
    </div>}
  </>
  );
}

export default App;
