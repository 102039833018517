import {BaseQueryFn, FetchArgs, fetchBaseQuery, FetchBaseQueryError,} from '@reduxjs/toolkit/query'

const baseQuery = fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_SERVER_PATH}`,
    prepareHeaders(headers) {
        headers.set('Content-Type', 'application/json')
        return headers;
    }
});

export const baseQueryWithReauth: BaseQueryFn<string | FetchArgs,
    unknown,
    FetchBaseQueryError> = async (args, api, extraOptions) => {
    let result = await baseQuery(args, api, extraOptions);
    console.info('interceptors login', result);
    if (result.error && result.error.status === 401) {
    }
    return result
};
