import { faUser, faLock } from "@fortawesome/free-solid-svg-icons";
import { faArrowRightFromBracket } from "@fortawesome/free-solid-svg-icons";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { emailValidator, valueValidator } from "../../core/validation";
import LoginNameInput from "../formFields/loginNameInput";
import PasswordInput from "../formFields/passwordInput";

import { Message } from "../../localization/Message";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "antd";


const LoginForm = ({
  handleSubmit,
  localization,
  loggedUserFetching,
  reset,
  selected
}: any) => {

  const registerLinkGenerator = (): string => {
    return `${process.env.REACT_APP_SMYLOR_URL}signup`
  }

  const policyLinkGenerator = (): string => {
    return `${process.env.REACT_APP_SMYLOR_URL}privacy`
  }

  return (
    <div className="container">
      <div className="form-box">
        <div className="body-form">
          <Form
            onSubmit={handleSubmit}
            render={(formRenderProps: FormRenderProps) => (
              <FormElement>
                <div className="mb-4">
                  <Field
                    name={"Email"}
                    type={"text"}
                    placeholder={localization.toLanguageString(
                      "login_module_email",
                      "E-mail"
                    )}
                    component={LoginNameInput}
                    validator={emailValidator}
                    icon={faUser}
                    autoComplete={"off"}
                  />
                </div>
                <Field
                  name={"Password"}
                  type={"password"}
                  placeholder={localization.toLanguageString(
                    "login_module_password",
                    "password"
                  )}
                  component={PasswordInput}
                  validator={valueValidator}
                  icon={faLock}
                  autoComplete={"off"}
                />
                {/* {reset ? (
                  <SC.CenterItemWrapper>
                    <SC.LinkTest href="/forgetpassword">
                      {localization.toLanguageString(
                        "login_module_dont_remember_password",
                        "Don't remember your password?"
                      )}
                    </SC.LinkTest>
                  </SC.CenterItemWrapper>
                ) : (
                  <div className="mb-3"></div>
                )} */}
                <div>
                  {/* <LoadingButton
                    defaultMessage={"LOG IN"}
                    messageKey={"login"}
                    loading={loggedUserFetching}
                    disabled={!formRenderProps.allowSubmit}
                    light={false}
                    icon={faArrowRightFromBracket as IconProp}
                    type={"submit"}
                    pinkBlue={true}
                  /> */}
                  <Button
                    className="w-full text-md mt-4 py-4 h-[40px] uppercase !text-white bg-smylor-blue-dark-pro hover:!bg-smylor-pink-pro border-0"
                    htmlType={"submit"}
                  >
                    <Message defaultMessage="login" messageKey="LOG IN" />
                    <FontAwesomeIcon icon={faArrowRightFromBracket as IconProp} />
                  </Button>
                </div>
                {<div className="mt-4 flex flex-row justify-center text-md">
                  {localization.toLanguageString(
                    "login_module_register_account_text",
                    "Dont Have an account Register "
                  )}
                  <span>&nbsp;</span>
                  <a href={registerLinkGenerator()} target="_blank" className="login-link">
                    {localization.toLanguageString(
                      "login_module_register_here",
                      " Here"
                    )}
                  </a>
                </div>}
                <div className="mt-4 flex flex-row justify-center ">
                  <a href={policyLinkGenerator()} target="_blank" className="login-link">
                    {localization.toLanguageString(
                      "login_module_privacy_terms",
                      "privacy-terms"
                    )}
                  </a>
                </div>
              </FormElement>
            )}
          />
          {/* <div>
              <input type="checkbox" /> Remember ME
            </div> */}
        </div>
      </div>
    </div>
  );
};

export default LoginForm;
