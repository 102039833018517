import Card from '../../modules/card'
import {
  Form,
  Field,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { checkBoxNormalValidator, checkBoxValidator, dateOfBirthValidator, dropdownValidator, emailValidator, InputValidator, smylorPhoneNumberValidator } from '../../core/validation';
import { BirthDayInputPicker, CheckBoxInput, CheckBoxNormalInput, EmailInput, GenderInput, NameInput, TexInput } from '../../components/formFields';
import SmylorCutomPhoneNumberInput from '../../components/formFields/phoneNumberInput';
import { Label } from '@progress/kendo-react-labels';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { customerInformation, referenceId } from '../bookingDataSlice';
import { useCreateAppoinmnetMutation } from '../../api/bookingApiSlice';
import { TAppointmentCreate } from '../../types/appointmentTypes';
import { RootState } from '../../store/store';
import WidgetLoader from '../../components/loader';
import './index.scss'
import { useLocalization } from '@progress/kendo-react-intl';
import { Message } from '../../localization/Message';
import { useState } from 'react';



const placeHolderDrawer = (selectedLang: "en_us" | "de_ch") => {
  let placeHolder;
  if (selectedLang === "en_us") placeHolder = "DD/MM/YYYY";
  else if (selectedLang === "de_ch") placeHolder = "TT.MM.JJJJ";
  return placeHolder;
};

const dateformat = (selectedLang: "en_us" | "de_ch") => {
  let fromat;
  if (selectedLang === "en_us") fromat = "dd/MM/yyyy";
  else if (selectedLang === "de_ch") fromat = "dd.MM.yyyy";
  return fromat;
};

const CustomerDetails = () => {
  const dispatch = useAppDispatch();
  const [triggerAppoinmentCreate, { isLoading }] = useCreateAppoinmnetMutation();
  const ServiceId = useAppSelector((state: RootState) => state.bookingData.treatment?.id);
  const PractitionerId = useAppSelector((state: RootState) => state.bookingData.randomTimeSlot?.PractitionerId);
  const ClientId = useAppSelector((state: RootState) => state.bookingData.clientId);
  const timeSlotData = useAppSelector((state: RootState) => state.bookingData.randomTimeSlot);
  const selectedLang = useAppSelector((state: RootState) => state.bookingData.selectedLng);
  const [toggleSelector, setToggleSelector] = useState<boolean>(true);
  const localization = useLocalization();

  const genderData = [
    {
      name: localization.toLanguageString("booking_widget_male", "Male"),
      id: 2,
    },
    {
      name: localization.toLanguageString("booking_widget_female", "Female"),
      id: 1,
    },
    {
      name: localization.toLanguageString("booking_widget_other", "Other"),
      id: 3,
    },
  ];

  const titleRender = () => {
    return <div className='treatment-title-wrapper'>
      {isLoading && <div className='w-8'><WidgetLoader /></div>}
      <div className="treatment-title">
        <Message messageKey='booking_widget_contact_info' defaultMessage='Contact Info' />
      </div>
    </div>
  };

  const onSubmitClick = ({ values, isValid }: any) => {
    const contactData = { ...values, Gender: values?.Gender?.id, DateOfBirth: values?.DateOfBirth?.toISOString(), }
    if (isValid) {
      dispatch(customerInformation({ contactInfo: contactData }))
      const bookingData: TAppointmentCreate = {
        ...contactData,
        AppointmentStatus: 3,
        ClientId, PractitionerId,
        ServiceId,
        StartDate: timeSlotData?.StartDate,
        EndDate: timeSlotData?.EndDate
      }
      triggerAppoinmentCreate(bookingData)
        .unwrap()
        .then(res => { dispatch(referenceId(res.Data)) })
        .catch(err => { console.log('err', err) });
    }
  }
  const contentRender = () => {
    return (
      <div className="treatment-list !p-4">
        <Form
          onSubmitClick={onSubmitClick}
          render={(formRenderProps: FormRenderProps) => (<FormElement>
            <div className='pb-2 input-custom'>
              <Label>
                <Message
                  messageKey="booking_widget_first_name"
                  defaultMessage="First Name"
                />
              </Label>
              <Field
                name={"FirstName"}
                component={NameInput}
                validator={InputValidator}
              />
            </div>
            <div className='pb-2  input-custom'>
              <Label>
                <Message
                  messageKey="booking_widget_last_name"
                  defaultMessage="Last Name"
                />
              </Label>
              <Field
                name={"LastName"}
                component={NameInput}
                validator={InputValidator}
              />
            </div>
            <div className='pb-2  input-custom'>
              <Label>
                <Message
                  messageKey="booking_widget_email"
                  defaultMessage="E mail"
                />
              </Label>
              <Field
                name={"Email"}
                component={EmailInput}
                // onChange={
                //   checkEmail
                //     ? (e: any) => onChangeEmail(e, formRenderProps)
                //     : undefined
                // }
                validator={emailValidator}
              />
            </div>
            <div className='pb-2 input-custom'>
              <Label>
                <Message
                  messageKey="booking_widget_gender"
                  defaultMessage="Gender"
                />
              </Label>
              <Field
                name={"Gender"}
                textField={"name"}
                data={genderData}
                // onChange={onGenderChange}
                component={GenderInput}
                validator={dropdownValidator}
              />
            </div>
            <div className='pb-2  input-custom'>
              <Field
                name={"PhoneNumber"}
                label={localization.toLanguageString(
                  "booking_widget_phone_number",
                  "Phone Number"
                )}
                component={SmylorCutomPhoneNumberInput}
                placeholder={localization.toLanguageString('enter_phone_number', "Enter phone number")}
                defaultCountry={'DE'}
                validator={smylorPhoneNumberValidator}
              />
            </div>
            <div className='pb-2  input-custom'>
              <Label>
                <Message
                  messageKey="booking_widget_date_of_birth"
                  defaultMessage="Date Of Birth"
                />
              </Label>
              <Field
                name={"DateOfBirth"}
                format={dateformat(selectedLang)}
                placeholder={placeHolderDrawer(selectedLang)}
                component={BirthDayInputPicker}
                validator={dateOfBirthValidator}
              />
            </div>
            <div className='flex flex-col items-start gap-2  input-custom'>
              <Label>
                <Message
                  messageKey="booking_widget_check_birthday"
                  defaultMessage="Check the Birthday"
                />
              </Label>
              <Field
                name={"CheckBirthDay"}
                component={CheckBoxNormalInput}
                validator={checkBoxNormalValidator}
              />
            </div>
            <div className=' input-custom'>
              <Field
                name={"Description"}
                label={localization.toLanguageString(
                  "booking_widget_other_information",
                  "Other Information"
                )}
                maxLength={250}
                value={formRenderProps.valueGetter("otherInfromation")}
                component={TexInput}
              />
            </div>
            <Field
              name={"AcceptPrivacy"}
              component={CheckBoxInput}
              validator={checkBoxValidator}
            // privacyUrl={privacyUrl}
            />
            <div className="flex">
              <button type="submit" className="
                booking-buuton
                py-2.5 px-5 font-medium text-gray-900 focus:outline-none
                bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:#fff focus:z-10 focus:ring-4
                flex-grow"
              >
                <Message
                  messageKey={"booking_widget_send_booking"}
                  defaultMessage={"Send Booking"}
                />
              </button>
            </div>
          </FormElement>)}
        />
      </div>
    );
  };

  const setToggle = () => {
    setToggleSelector(prev => !prev)
  };

  return (
    <div className="treatment-container">
      <div className="treatment-title pb-3"> <Message messageKey='booking_widget_uer_informantion' defaultMessage='User Informantion' /></div>
      <Card title={titleRender()} content={contentRender()} toggle={toggleSelector} setToggle={setToggle} />
    </div>
  )
}

export default CustomerDetails