import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReauth } from './interceptorsSlice';
import { TDentistDetailsRes, TGetDentistDetail, TTreatmentTypesRes } from '../types/treatmentTypes';
import { TGetPractitionerTimeSlotDetails, TGetPractitionerTimeSlotRes, TGetRandomTimeSlotDetails, TGetRandomTimeSlotRes } from '../types/timeSlotTypes';
import { TAppointmentCreate, TAppointmentCreateRes } from '../types/appointmentTypes';
import { TGetTranslationsRes } from '../types/localizationTypes';

export const bookingApiSlice = createApi({
  reducerPath: 'bookingApi',
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    getTranslations: builder.query<TGetTranslationsRes, void>({
      query: () => ({
        url: `Translation/GetAll`,
        method: 'GET'
      }),
    }),
    getTreatmentTypes: builder.query<TTreatmentTypesRes, number>({
      query: (id) => ({
        url: `Services/GetAllServices`,
        params: { clientId: id },
        method: 'GET'
      }),
    }),
    getDentistData: builder.query<TDentistDetailsRes, TGetDentistDetail>({
      query: ({ clientId, serviceId }) => ({
        url: `Practitioner/GetPractitionerByService`,
        params: { clientId, serviceId },
        method: 'GET'
      }),
    }),
    getRandomTimeSlot: builder.query<TGetRandomTimeSlotRes, TGetRandomTimeSlotDetails>({
      query: ({ clientId, serviceId, practitionerId, step }) => ({
        url: `FreeSlot/GetRandomFreeSlots`,
        params: { clientId, serviceId, practitionerId, step },
        method: 'GET'
      }),
    }),
    freeSlotsByPractitioner: builder.query<TGetPractitionerTimeSlotRes, TGetPractitionerTimeSlotDetails>({
      query: ({ clientId, serviceId, practitionerId, date }) => ({
        url: `FreeSlot/GetFreeSlotsByPractitioner`,
        params: { clientId, serviceId, practitionerId, date },
        method: 'GET'
      }),
    }),
    createAppoinmnet: builder.mutation<TAppointmentCreateRes, TAppointmentCreate>({
      query: (data) => ({
        url: '/Booking/Create',
        method: 'POST',
        body: data,
      }),
    })
  }),
});

export const {
  useLazyGetTreatmentTypesQuery,
  useLazyGetDentistDataQuery,
  useLazyGetRandomTimeSlotQuery,
  useCreateAppoinmnetMutation,
  useLazyFreeSlotsByPractitionerQuery,
  useLazyGetTranslationsQuery
} = bookingApiSlice