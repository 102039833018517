import { FormElementProps } from "@progress/kendo-react-form";
import { Error, Hint, Label } from "@progress/kendo-react-labels";
import {
    Input,
    TextArea,
    Checkbox,
    RadioGroup,
    MaskedTextBox
} from "@progress/kendo-react-inputs";
import { DropDownList } from "@progress/kendo-react-dropdowns";
// import { Message } from "../../localization/message";
// import { useLocalization, } from "@progress/kendo-react-intl";
import { DatePicker } from "@progress/kendo-react-dateinputs";
import { CustomPickerWrap, CustomPopup } from "./dateOfbirthCustomProps";
import './index.scss';
import { Message } from "../../localization/Message";
import { useLocalization } from "@progress/kendo-react-intl";

const MaskInput = (fieldRenderProps: any) => {
    const {
        validationMessage,
        visited,
        hint,
        required,
        onFocusEvent,
        setName,
        ...others
    } = fieldRenderProps;
    return (
        <div>
            <MaskedTextBox
                // required={true}
                {...others}
                onFocus={onFocusEvent}
            />
            {visited && validationMessage && (
                <Error>
                    Field validation error
                    {/* <Message
                        messageKey={validationMessage}
                        defaultMessage={"Field validation error"}
                    />{" "} */}
                </Error>
            )}
        </div>
    );
};

const NameInput = (fieldRenderProps: any) => {
    const {
        validationMessage,
        visited,
        hint,
        required,
        onFocusEvent,
        setName,
        ...others
    } = fieldRenderProps;
    return (
        <div>
            <Input
                // required={true}
                {...others}
                onFocus={onFocusEvent}
            />
            {visited && validationMessage && (
                <Error>
                    <Message
                        messageKey={validationMessage}
                        defaultMessage={"Field validation error"}
                    />{" "}
                </Error>
            )}
        </div>
    );
};

const RadioInput = (fieldRenderProps: any) => {
    const {
        validationMessage,
        visited,
        hint,
        required,
        onFocusEvent,
        setName,
        ...others
    } = fieldRenderProps;
    return (
        <div>
            <RadioGroup
                // required={true}
                {...others}
                onFocus={onFocusEvent}
            />
            {visited && validationMessage && (
                <Error>
                    <Message
                        messageKey={validationMessage}
                        defaultMessage={"Field validation error"}
                    />
                </Error>
            )}
        </div>
    );
};

const TexInput = (fieldRenderProps: any) => {
    const {
        validationMessage,
        visited,
        hint,
        maxLength,
        value,
        label,
        id,
        defaultValue,
        onFocusEvent,
        ...others
    } = fieldRenderProps;
    return (
        <>
            <div>
                <Label id={id}>{label}</Label>
                <TextArea
                    defaultValue={defaultValue}
                    // required={true}
                    maxLength={maxLength}
                    {...others}
                    onFocus={onFocusEvent}
                />
                {visited && validationMessage && (
                    <Error>
                        <Message
                            messageKey={validationMessage}
                            defaultMessage={"Field validation error"}
                        />
                    </Error>
                )}
            </div>
            <div>
                <Hint direction={"end"}>
                    {value ? value.length : 0} / {maxLength}
                </Hint>
            </div>
        </>
    );
};

const EmailInput = (fieldRenderProps: any) => {
    const { validationMessage, visited, value, onFocusEvent, ...others } =
        fieldRenderProps;
    return (
        <div>
            <Input
                value={value}
                // required={true}
                type={"email"}
                {...others}
                onFocus={onFocusEvent}
            />
            {visited && validationMessage && (
                <Error>
                    <Message
                        messageKey={validationMessage}
                        defaultMessage={"Field validation error"}
                    />
                </Error>
            )}
        </div>
    );
};

const PhoneNumberInput = (fieldRenderProps: any) => {
    const { validationMessage, visited, hint, onFocusEvent, ...others } =
        fieldRenderProps;
    return (
        <div>
            <Input
                // required={true}
                {...others}
                onFocus={onFocusEvent}
            />
            {visited && validationMessage && (
                <Error>
                    <Message
                        messageKey={validationMessage}
                        defaultMessage={"Field validation error"}
                    />
                </Error>
            )}
        </div>
    );
};

const GenderInput = (fieldRenderProps: any) => {
    const { validationMessage, visited, hint, onFocusEvent, ...others } =
        fieldRenderProps;
    return (
        <div>
            <DropDownList
                className="check"
                required={false} {...others} onFocus={onFocusEvent} />
            {visited && validationMessage && (
                <Error>
                    <Message
                        messageKey={validationMessage}
                        defaultMessage={"Field validation error"}
                    />{" "}
                </Error>
            )}
        </div>
    );
};

const CheckBoxInput = (fieldRenderProps: any) => {
    const localization = useLocalization();
    const { validationMessage, visited, hint, onFocusEvent, privacyUrl, ...others } =
        fieldRenderProps;
    return (
        <div className="checkboxCustomStyle">
            <Checkbox
                // required={true}
                {...others}
                onFocus={onFocusEvent}
                className={"checkboxCustomStyle"}
            >
                <Message
                    messageKey={"booking_widget_dataprivacy_bodyText_01"}
                    isHtml={true}
                    args={[
                        `<a class="check-link" href=${privacyUrl ?? localization.toLanguageString(
                            "booking_widget_privacy_url",
                            "https://zahncal.com/dentist-solutions/smylor/privacy"
                        )} target="_blank">${localization.toLanguageString(
                            "booking_widget_dataprivacy_keyText_01",
                            "privacy policy"
                        )}</a>`,
                    ]}
                    defaultMessage={
                        "I have read Smylor’s {0}  and I allow Smylor to process my personal data solely for the purpose of arranging appointments."
                    }
                />
            </Checkbox>
            {visited && validationMessage && (
                <Error>
                    <Message
                        messageKey={validationMessage}
                        defaultMessage={"Field validation error"}
                    />
                </Error>
            )}
        </div>
    );
};
const CheckBoxNormalInput = (fieldRenderProps: any) => {

    const { validationMessage, visited, hint, onFocusEvent, privacyUrl, ...others } =
        fieldRenderProps;
    return (
        <div className="checkboxCustomStyle">
            <Checkbox
                // required={true}
                {...others}
                onFocus={onFocusEvent}
                className={"checkboxCustomStyle"}
            >
                <Message
                    messageKey={"booking_widget_date_of_birth_check"}
                    isHtml={true}
                    defaultMessage={
                        "I have checked that this date of birth is correct."
                    }
                />
            </Checkbox>
            {visited && validationMessage && (
                <Error>
                    <Message
                        messageKey={validationMessage}
                        defaultMessage={"Field validation error"}
                    />
                </Error>
            )}
        </div>
    );
};

const BirthDayInputPicker = (fieldRenderProps: any) => {
    const { validationMessage, visited, hint, onFocusEvent, ...others } =
        fieldRenderProps;
    return (
        <div>
            <DatePicker
                popup={CustomPopup}
                calendar={CustomPickerWrap}
                {...others}
                onFocus={onFocusEvent}
                required={false}
            />
            {visited && validationMessage && (
                <Error>
                    <Message
                        messageKey={validationMessage}
                        defaultMessage={"Field validation error"}
                    />
                </Error>
            )}
        </div>
    );
};

export {
    NameInput,
    TexInput,
    EmailInput,
    PhoneNumberInput,
    GenderInput,
    CheckBoxInput,
    BirthDayInputPicker,
    RadioInput,
    MaskInput,
    CheckBoxNormalInput
};
