import React, { useEffect, useState } from 'react'
import Card from '../../modules/card';
import { default as userDefaultLogo } from '../../components/header/avatar.png';
import { useLazyGetTreatmentTypesQuery } from '../../api/bookingApiSlice';
import { TTreatmentType } from '../../types/treatmentTypes';
import WidgetLoader from '../../components/loader';
import { setClientId, setDoctorId, setServiceId, treatmentData } from '../bookingDataSlice';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { RootState } from '../../store/store';
import { Message } from '../../localization/Message';

const TreatmentTypes = () => {

    const clientId = useAppSelector((state: RootState) => state.bookingData.clientId);
    const service = useAppSelector((state: RootState) => state.bookingData.serviceId);
    const [triggerGetTreatmentTypes, { isLoading, data: treatmentTypeData }] = useLazyGetTreatmentTypesQuery();
    const treatment = useAppSelector((state: RootState) => state.bookingData.treatment);
    const [selectItem, setSelectItem] = useState<TTreatmentType | null>(treatment);
    const [toggleSelector, setToggleSelector] = useState<boolean>(false);
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (clientId) {
            triggerGetTreatmentTypes(clientId);
        }
    }, [clientId]);

    useEffect(() => {
        if (service) {
            setToggleSelector(false);
        } else if (selectItem) {
            setToggleSelector(prev => !prev);
        } else if (isLoading) {
            setToggleSelector(false);
        } else {
            setToggleSelector(true);
        }
    }, [JSON.stringify(selectItem), isLoading]);

    useEffect(() => {
        if (treatmentTypeData && service) {
            const queryTreatment = treatmentTypeData.Data.find((treatment) => treatment.id == service);
            if (queryTreatment) {
                setSelectItem(queryTreatment);
                dispatch(treatmentData({ step: 2, treatment: queryTreatment }));
            }
        }
    }, [JSON.stringify(treatmentTypeData?.Data)])

    const titleRender = () => {
        return <div className='treatment-title-wrapper'>
            {isLoading && <div className='w-8'><WidgetLoader /></div>}
            <div className="treatment-title">
                {selectItem ?
                    <div className='list-item-container'>
                        <div className='selected'></div>
                        <img className='min-avator' src={selectItem.image ? selectItem.image : userDefaultLogo} />
                        {selectItem.service}
                    </div>
                    :
                    <Message messageKey='booking_widget_treatment_title' defaultMessage='Emergency/ Implant Consultation/ Filling/ Crown' />
                }
            </div>
        </div>
    };

    const onSelectItem = (item: TTreatmentType, currentItem: TTreatmentType | null) => {
        dispatch(setDoctorId(null));
        if (currentItem?.id == item.id) {
            setSelectItem(null);
            dispatch(setServiceId(null));
            dispatch(treatmentData({ step: 1, treatment: null }));
        } else {
            setSelectItem(item);
            dispatch(treatmentData({ step: 2, treatment: item }));
            dispatch(setServiceId(item.id));
        }
    }

    const contentRender = () => {
        return (
            <div className="treatment-list">
                {treatmentTypeData?.Data && treatmentTypeData.Data.map(((treatment: TTreatmentType, index) => <div key={index} className={`treatment text-left ${selectItem?.id == treatment.id ? 'item-selected' : ''}`}>
                    <div onClick={() => onSelectItem(treatment, selectItem)} className='list-item-container'>
                        {selectItem?.id == treatment.id ? <div className='selected'></div> : ''}
                        <img className='min-avator' src={treatment.image ? treatment.image : userDefaultLogo} />
                        {treatment.service}
                    </div>
                </div>))}
            </div>
        );
    };

    const setToggle = () => {
        setToggleSelector(prev => !prev)
    };

    return (
        <div className="treatment-container !pt-6">
            <div className="treatment-title pb-3"> <Message defaultMessage='Treatment Types' messageKey='booking_widget_treatment_types' /></div>
            <Card title={titleRender()} content={contentRender()} toggle={toggleSelector} setToggle={setToggle} />
        </div>
    )
}

export default TreatmentTypes

