import React, { useCallback } from 'react';
import Moment from 'react-moment';
import { RootState } from '../../store/store';
import { useAppSelector } from '../../store/hooks';

const DateDrawer = ({date}: any) => {
    const selectedLang = useAppSelector((state: RootState) => state.bookingData.selectedLng);
    const setDate = useCallback(
        (date: any) => {
            switch (selectedLang) {
                case 'en_us':
                    return <Moment format="DD/MM/YYYY">{date}</Moment>;
                case 'de_ch':
                    return <Moment format="DD.MM.YYYY">{date}</Moment>;;
                default: break;
            }
        },
        [selectedLang],
    )
    return (
        <>
            {setDate(date)}
        </>
    )
}

export default DateDrawer;