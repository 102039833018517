import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { TDentistDetail, TTreatmentType } from '../types/treatmentTypes'
import { NamedTupleMember } from 'typescript';
import { TRandomTimeSlot } from '../types/timeSlotTypes';
import { TCustomerInfo } from '../types/contactInfoTypes';
import { TUserProfile } from '../types/profile.types';

interface TBookingState {
  user: TUserProfile | null;
  selectedLng: "de_ch" | "en_us";
  step: number;
  clientId: number | null;
  serviceId: number | null;
  doctorId: number | null;
  treatment: TTreatmentType | null;
  doctorList: TDentistDetail[] | null | undefined;
  doctor: TDentistDetail | null;
  slotDoctor: TDentistDetail | null;
  randomTimeSlot: TRandomTimeSlot | null;
  contactInfo: TCustomerInfo | null;
  referenceId: string | null
}

const initialState: TBookingState = {
  user: null,
  selectedLng: "de_ch",
  step: 1,
  clientId: null,
  serviceId: null,
  doctorId: null,
  treatment: null,
  doctorList: null,
  doctor: null,
  slotDoctor: null,
  randomTimeSlot: null,
  contactInfo: null,
  referenceId: null
}

const bookingDataSlice = createSlice({
  name: 'bookingData',
  initialState,
  reducers: {
    setUser(state, action: PayloadAction<TUserProfile | null>) {
      state.user = action.payload
    },
    chageSelectedLng(state, action: PayloadAction<"de_ch" | "en_us">) {
      state.selectedLng = action.payload
    },
    setClientId(state, action: PayloadAction<number>) {
      state.clientId = action.payload
    },
    setServiceId(state, action: PayloadAction<number | null>) {
      state.serviceId = action.payload
    },
    setDoctorId(state, action: PayloadAction<number | null>) {
      state.doctorId = action.payload
    },
    treatmentData(state, action: PayloadAction<{ treatment: TTreatmentType | null, step: number }>) {
      state.treatment = action.payload.treatment;
      state.step = action.payload.step;
      state.doctorList = null;
      state.doctor = null;
      state.slotDoctor = null;
      state.randomTimeSlot = null;
      state.contactInfo = null;
      state.referenceId = null;
    },
    doctorData(state, action: PayloadAction<{ doctor: TDentistDetail | null, doctorList: TDentistDetail[] | null | undefined, step: number }>) {
      state.doctor = action.payload.doctor;
      state.doctorList = action.payload.doctorList;
      state.slotDoctor = null;
      state.step = action.payload.step;
      state.randomTimeSlot = null;
      state.contactInfo = null;
      state.referenceId = null;
    },
    randomTimeSlotData(state, action: PayloadAction<{ randomTimeSlot: TRandomTimeSlot | null, slotDoctor: TDentistDetail | null, step: number }>) {
      state.randomTimeSlot = action.payload.randomTimeSlot;
      state.slotDoctor = action.payload.slotDoctor;
      state.step = action.payload.step
    },
    customerInformation(state, action: PayloadAction<{ contactInfo: TCustomerInfo }>) {
      state.contactInfo = action.payload.contactInfo;
    },
    referenceId(state, action: PayloadAction<string>) {
      state.referenceId = action.payload;
      state.step = 5
    },
    progressStepChange(state, action: PayloadAction<number>) {

      switch (action.payload) {
        case 0:
          return initialState
          break;
        case 1:
          state.doctor = null;
          state.randomTimeSlot = null;
          state.contactInfo = null;
          state.referenceId = null;
          state.step = 2;
          break;
        case 2:
          state.randomTimeSlot = null;
          state.contactInfo = null;
          state.referenceId = null;
          state.step = 3;
          break;
        default:
          return state;
      }
    }
  },
})

export const {
  treatmentData,
  doctorData,
  setClientId,
  setDoctorId,
  setServiceId,
  randomTimeSlotData,
  customerInformation,
  referenceId,
  progressStepChange,
  chageSelectedLng,
  setUser
} = bookingDataSlice.actions

export default bookingDataSlice.reducer