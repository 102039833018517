import React, { useEffect, useState } from "react";
import './index.scss';
import { default as smylorLogo } from './smylor-pro.png';
import { default as userDefaultLogo } from './avatar.png';
import { Chooser } from "../../localization/Chooser";
import LoginMenu from "../loginModule";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { RootState } from "../../store/store";
import { Popover } from "antd";
import Cookies from "js-cookie";
import { setUser } from "../../views/bookingDataSlice";
import { logoutUser } from "../../core/utils";
import { Message } from "../../localization/Message";
import { faArrowRightFromBracket } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Header = () => {
  const [showLogin, setShowLogin] = useState<boolean>(false);
  const [openMenu, setOpenMenu] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const [cookieValue, setCookieValue] = useState('');
  const queryParams = new URLSearchParams(window.location.search);
  const userDetails = useAppSelector((state: RootState) => state.bookingData.user);
  const code = queryParams.get("code");

  useEffect(() => {
    const getCookie = (value: string) => {
      const cookie = Cookies.get(value)
      if (cookie) return cookie
      else return '';
    };

    const intervalId = setInterval(() => {
      const updatedValue = getCookie('u-in');
      if (updatedValue !== cookieValue) {
        setCookieValue(updatedValue);
        !updatedValue && logoutUser()
      }
    }, 1000);
    return () => clearInterval(intervalId);
  }, [cookieValue]);
  

  const onLoginClick = () => {
    const strSession = Cookies.get('u-in');
    const user = strSession ? JSON.parse(strSession) : null;
    if (user ) {
      user && dispatch(setUser(user));
      setOpenMenu(true);
    } else {
      setShowLogin(true);
    }
  };

  const loginMenuContent = () => {
    return (
      <div>
        <div className="flex flex-row gap-4 justify-center items-center px-3 py-4">
          <img className="user-img border rounded-full h-[40px]" src={userDetails?.picture ? userDetails?.picture : userDefaultLogo} />
          <div className="flex flex-col">
            <span className="name font-medium">{userDetails?.nickName}</span>
            <span className="email">{userDetails?.email}</span>
          </div>
        </div>
        <div className="border-t">
          <div onClick={logoutUser}className="flex flex-row gap-2 w-full cursor-pointer items-center p-3 pt-2 hover:bg-gray-300">
            <FontAwesomeIcon icon={faArrowRightFromBracket} />
            <span className="text-sm">
              <Message messageKey="chat_app_profile_logout" defaultMessage="Ausloggen" />
            </span>
          </div>
        </div> 
      </div>
    )
  }

  return (
    <div>
      {openMenu && <div className="k-overlay" style={{ zIndex: 1000 }} onClick={() => setOpenMenu(false)}></div>}
      <LoginMenu setShowMenu={setShowLogin} showMenu={showLogin} code={code}/>
      <div className="mx-auto flex items-center justify-between px-2 sm:px-4 lg:max-w-7xl header-wrapper">
        <div className="flex items-center gap-2 sm:gap-4">
          <a aria-label="Home" href="/">
            <img className="logo-img" src={smylorLogo} />
          </a>
        </div>

        <div className="flex items-center gap-4 sm:gap-8">
          <Chooser />
          <Popover placement="bottomRight" content={loginMenuContent} arrow={false} open={openMenu}>
            <img className="user-img border rounded-full cursor-pointer" src={userDetails?.picture ? userDetails?.picture : userDefaultLogo} onClick={onLoginClick} />
          </Popover>

        </div>
      </div>
    </div>
  );
};

export default Header;
