import { Label } from "@progress/kendo-react-labels";
import { Error, Hint } from "@progress/kendo-react-labels";
import { FieldRenderProps } from "@progress/kendo-react-form";
import PhoneInput, { isPossiblePhoneNumber } from 'react-phone-number-input';
import 'react-phone-number-input/style.css'
import './index.scss'
import { Message } from "../../localization/Message";
// import { SmylorCutomPhoneNumberWrapper } from "./formFields.styled";
// import { Message } from "../../localization/message";

const SmylorCutomPhoneNumberInput = (fieldRenderProps: FieldRenderProps) => {
    const { label, visited, validationMessage, onChange, value, placeholder, defaultCountry } =
        fieldRenderProps;
    const onChangeNumber = (value: any) => {
        onChange({ value: value });
    };

    return (
        <div className="phone-number-custom">
            <Label>{label}</Label>
            <PhoneInput
                defaultCountry={defaultCountry}
                international={true}
                useNationalFormatForDefaultCountryValue={true}
                placeholder={placeholder}
                value={value}
                onChange={onChangeNumber} />
            {visited && validationMessage && (
                <Error>
                    <Message
                        messageKey={validationMessage}
                        defaultMessage={"Field validation error"}
                    />
                </Error>
            )}
        </div>
    );
};

export default SmylorCutomPhoneNumberInput;
