import "./index.scss";
import TreatmentTypes from "./treatmentTypes";
import { default as userDefaultLogo } from '../components/header/smylor-pro.png';
import DoctorList from "./DoctorList";
import TreatmentAvailability from "./TreatmentAvailability";
import { useAppSelector } from "../store/hooks";
import { RootState } from "../store/store";
import CustomerDetails from "./customerDetails";
import BookingCreateView from "./bookingCreate";
import StepperComponent from "./stepper";
import { Message } from "../localization/Message";

const BookingWidget = () => {
    const step = useAppSelector((state: RootState) => state.bookingData.step);

    const stepRender = () => {
        // switch (step) {
        //     case 1:
        //         return <TreatmentTypes />;
        //     case 2:
        //         return (<>
        //             <TreatmentTypes />
        //             <DoctorList />
        //         </>);
        //     case 3:
        //         return (<>
        //             <TreatmentTypes />
        //             <DoctorList />
        //             <TreatmentAvailability />
        //         </>);
        //     case 4:
        //         return (<>
        //             <TreatmentTypes />
        //             <DoctorList />
        //             <TreatmentAvailability />
        //             <CustomerDetails />
        //         </>);
        //     case 5:
        //         return <BookingCreateView />
        //     default:
        //         return <></>;
        // }
    };

    return (
        <div className="main-body">
            <div className="body-content
            xl:w-[1260px] 
            lg:w-[calc(100%-32px)] 
            sm:w-[calc(100%-16px)]
            ">
                <div className="title">
                    <Message messageKey='booking_widget_book_appoinment_online' defaultMessage='Book appointment online' />
                </div>
                <div className="devider"></div>
                <div className="mt-6">
                    <StepperComponent />
                </div>
                {/* {stepRender()} */}
                {step == 5 ? <BookingCreateView /> : 
                <>
                {step >= 1 && <TreatmentTypes/>}
                {step >= 2 && <DoctorList/>}
                {step >= 3 && <TreatmentAvailability />}
                {step >= 4 && <CustomerDetails />}
                </>
                }
                <div className="main-footer">
                    <img className="footer-img" src={userDefaultLogo} />
                    <div className="footer-url">Booking App Smylor.com</div>
                </div>
            </div>
        </div>
    );
};

export default BookingWidget;
