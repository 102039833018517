import React from 'react'
import { useAppSelector } from '../../store/hooks';
import { RootState } from '../../store/store';
import Moment from 'react-moment';
import { Message } from '../../localization/Message';

const BookingCreateView = () => {
    const bookingData = useAppSelector((state: RootState) => state.bookingData);

    const onContinueClick = () => {
        window.open(window.location.href, '_self');
    };
    
    return (
        <div className='my-4 mx-2 md:m-4'>
            <div className='w-full flex-grow bg-white border border-gray-200 rounded-lg shad'>
                <h4 className="text-3xl font-semibold title  justify-center !py-4 md:my-6">
                    <Message
                        messageKey={"booking_widget_summary_booking_success"}
                        defaultMessage={"Booking Success...!"}
                    />
                </h4>
                <ul>
                    <li className='p-1 md:p-1 pt-0'>
                        <figure className="relative flex bg-slate-50 rounded-lg p- justify-center  gap-2 success-list flex-wrap">
                            <div className='text-md md:text-xl font-medium flex flex-row gap-2'>
                                <Message
                                    messageKey={"booking_widget_summary_reference"}
                                    defaultMessage={"Reference Id"}
                                /> :</div>
                            <div className='text-md md:text-xl font-medium flex flex-row gap-2'>{bookingData.referenceId}</div>
                        </figure>
                    </li>
                    <li className='p-1 md:p-1 pt-0'>
                        <figure className="relative flex bg-slate-50 rounded-lg p-2  justify-center gap-2 success-list flex-wrap">
                            <div className='text-md md:text-xl font-medium flex flex-row gap-2'>
                                <Message
                                    messageKey={"booking_widget_summary_treatment_type"}
                                    defaultMessage={"Treatment Type"}
                                /> :</div>
                            <div className='text-md md:text-xl font-medium flex flex-row gap-2'>{bookingData.treatment?.service}</div>
                        </figure>
                    </li>
                    <li className='p-1 md:p-1 pt-0'>
                        <figure className="relative flex bg-slate-50 rounded-lg p-2  justify-center gap-2 success-list flex-wrap">
                            <div className='text-md md:text-xl font-medium flex flex-row gap-2'>
                                <Message
                                    messageKey={"booking_widget_summary_practitioner_name"}
                                    defaultMessage={"Practitioner Name"}
                                />
                                :</div>
                            <div className='text-md md:text-xl font-medium flex flex-row gap-2'>{bookingData.slotDoctor?.fullName}</div>
                        </figure>
                    </li>
                    <li className='p-1 md:p-1 pt-0'>
                        <figure className="relative flex bg-slate-50 rounded-lg p-2  justify-center gap-2 success-list flex-wrap">
                            <div className='text-md md:text-xl font-medium flex flex-row gap-2'>
                                <Message
                                    messageKey={"booking_widget_summary_duration"}
                                    defaultMessage={"Duration"}
                                />
                                :</div>
                            <div className='text-md md:text-xl font-medium flex flex-row gap-2'>{bookingData.treatment?.minutes}</div>
                        </figure>
                    </li>
                    <li className='p-1 md:p-1 pt-0'>
                        <figure className="relative flex bg-slate-50 rounded-lg p-2  justify-center gap-2 success-list flex-wrap">
                            <div className='text-md md:text-xl font-medium flex flex-row gap-2'>
                                <Message
                                    messageKey={"booking_widget_summary_description"}
                                    defaultMessage={"Description"}
                                />
                                :</div>
                            <div className='text-md md:text-xl font-medium flex flex-row gap-2'>{bookingData.contactInfo?.Description}</div>
                        </figure>
                    </li>
                    <li className='p-1 md:p-1 pt-0'>
                        <figure className="relative flex bg-slate-50 rounded-lg p-2  justify-center gap-2 success-list flex-wrap">
                            <div className='text-md md:text-xl font-medium flex flex-row gap-2'>
                                <Message
                                    messageKey={"booking_widget_summary_date"}
                                    defaultMessage={"Date"}
                                />
                                :</div>
                            <div className='text-md md:text-xl font-medium flex flex-row gap-2'><Moment format="DD/MM/YYYY">{bookingData.randomTimeSlot?.StartDate}</Moment></div>
                        </figure>
                    </li>
                    <li className='p-1 md:p-1 pt-0'>
                        <figure className="relative flex bg-slate-50 rounded-lg p-2  justify-center gap-2 success-list flex-wrap">
                            <div className='text-md md:text-xl font-medium flex flex-row gap-2'>
                                <Message
                                    messageKey={"booking_widget_summary_timeslot"}
                                    defaultMessage={"TimeSlot"}
                                />
                                :</div>
                            <div className='text-md md:text-xl font-medium flex flex-row gap-2'><Moment format="HH:mm">{bookingData.randomTimeSlot?.StartDate}</Moment> - <Moment format="HH:mm">{bookingData.randomTimeSlot?.EndDate}</Moment></div>
                        </figure>
                    </li>
                    <li className='p-1 md:p-1 pt-0'>
                        <figure className="relative flex bg-slate-50 rounded-lg p-2  justify-center gap-2 success-list flex-wrap">
                            <div className='text-md md:text-xl font-medium flex flex-row gap-2'>
                                <Message
                                    messageKey={"booking_widget_summary_offering_price"}
                                    defaultMessage={"Offering Price"}
                                />
                                :</div>
                            <div className='text-md md:text-xl font-medium flex flex-row gap-2'>{bookingData.treatment?.offeringPrice}</div>
                        </figure>
                    </li>
                    <li className='p-1 md:p-1pt-0'>
                        <figure className="relative flex bg-slate-50 rounded-lg p-2  justify-center gap-2 success-list flex-wrap">
                            <div className='text-md md:text-xl font-medium flex flex-row gap-2'>
                                <Message
                                    messageKey={"booking_widget_summary_commission_rate"}
                                    defaultMessage={"Commission Rate"}
                                />
                                :</div>
                            <div className='text-md md:text-xl font-medium flex flex-row gap-2'>{bookingData.treatment?.comissionRate} %</div>
                        </figure>
                    </li>
                    <li className='p-1 md:p-1 pt-0 md:pb-4'>
                        <figure className="relative flex bg-slate-50 rounded-lg p-2  justify-center gap-2 success-list flex-wrap">
                            <div className='text-md md:text-xl font-medium flex flex-row gap-2'>
                                <Message
                                    messageKey={"booking_widget_summary_total_amount"}
                                    defaultMessage={"Total Amount"}
                                />
                                :</div>
                            <div className='text-md md:text-xl font-medium flex flex-row gap-2'>{bookingData.treatment?.totalAmount} EUR</div>
                        </figure>
                    </li>

                </ul>
                <div className="flex mx-2 md:mx-4 mb-4">
                    <button type="button" className="
                booking-buuton
                py-2.5 px-5 font-medium text-gray-900 focus:outline-none
                bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:#fff focus:z-10 focus:ring-4
                 focus:ring-gray-100  
                   flex-grow"
                        onClick={onContinueClick}
                    >
                        <Message
                            messageKey={"booking_widget_continue"}
                            defaultMessage={"Continue"}
                        />
                    </button>
                </div>
            </div>
        </div>
    )
}

export default BookingCreateView;