import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import { bookingApiSlice } from '../api/bookingApiSlice';
import bookingDataSlice from '../views/bookingDataSlice'
import { loginApiSlice } from '../api/loginApiSlice';

export const store = configureStore({
  reducer: {
    bookingData: bookingDataSlice,
    [bookingApiSlice.reducerPath]: bookingApiSlice.reducer,
    [loginApiSlice.reducerPath]: loginApiSlice.reducer

  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware()
      .concat(bookingApiSlice.middleware).concat(loginApiSlice.middleware)
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
