import React, { useCallback } from 'react';
import Moment from 'react-moment';
import { useAppSelector } from '../../store/hooks';
import { RootState } from '../../store/store';

const TimeDrawer = ({date}: any) => {
    const selectedLang = useAppSelector((state: RootState) => state.bookingData.selectedLng);
    const setDate = useCallback(
        (date: any) => {
            switch (selectedLang) {
                case 'en_us':
                    return <Moment format="hh:mm A">{date}</Moment>;
                case 'de_ch':
                    return <Moment format="HH:mm">{date}</Moment>;;
                default: break;
            }
        },
        [selectedLang],
    )
    return (
        <>
            {setDate(date)}
        </>
    )
}

export default TimeDrawer;