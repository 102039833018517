import { Input } from "@progress/kendo-react-inputs";
import { FieldRenderProps } from "@progress/kendo-react-form";
import { Error } from "@progress/kendo-react-labels";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Message } from "../../localization/Message";

const LoginNameInput = (fieldRenderProps: FieldRenderProps) => {
  const {
    validationMessage,
    visited,
    hint,
    required,
    setName,
    onBlurEvent,
    onSelectEvent,
    grow,
    icon,
    ...others
  } = fieldRenderProps;

  return (
    <div className="input-wrapper">
      <div className="input-group mb-1">
        {icon && (
          <div className="input-group-prepend">
            <span className="input-group-text">
              <FontAwesomeIcon icon={icon} />
            </span>
          </div>
        )}
        <Input
          {...others}
          onBlur={onBlurEvent}
          onSelect={onSelectEvent}
          className="form-control"
        />
      </div>
      {visited && validationMessage && (
        <Error>
          <Message
            messageKey={validationMessage}
            defaultMessage={"Field validation error"}
          />
        </Error>
      )}
    </div>
  );
};

export default LoginNameInput;
