import Cookies from 'js-cookie';
import { USER_ROLES } from '../constant';

export const getCookiesValue = (key: string, needDecrypt = false) => { return Cookies.get(key) };

export const setCookiesValue = (key: string, value: any, needEncrypt = false) => {
    try {
      let prepValue = value;
      localStorage.setItem(key, prepValue);
      Cookies.set(key, value, { domain: `${process.env.REACT_APP_DOMAIN_URL}` });
    } catch (e) {
      console.log("-------- storage setting error --------");
      console.log("value ", value);
      console.log(e);
    }
  };

export const logoutUser = () => {

  const logoutChannel = new BroadcastChannel('logoutChannel');
  logoutChannel.postMessage({ type: 'logout' });
  logoutChannel.close();
  localStorage.removeItem('u-in');
  localStorage.removeItem('u-ses');
  Cookies.remove('u-in', { domain: process.env.REACT_APP_DOMAIN_URL });
  Cookies.remove('u-ses', { domain: process.env.REACT_APP_DOMAIN_URL });
  window.open(window.location.href, '_self');
}

export const setSession = (session: any) => {

    if (session.role === USER_ROLES.PARTICIPANT)
      window.open(process.env.REACT_APP_CHAT_APP_NAVIGATE_URL, '_self');
  
    localStorage.setItem('u-in', JSON.stringify(session));
    localStorage.setItem('u-ses', session.accessToken);
  
    Cookies.set('u-in', JSON.stringify(session), { domain: process.env.REACT_APP_DOMAIN_URL,
       expires : session.expiresIn
       });
    Cookies.set('u-ses', session.accessToken, { domain: process.env.REACT_APP_DOMAIN_URL,
       expires : session.expiresIn
       });
}

const profilePhotoSetter = (picture: string): string => {
    if (picture) {
        return picture.split(',')[0];
    }else return ''
};

export const sessionCreator = (payload: any) => {

    if (payload.UserType === USER_ROLES.DENTIST || payload.UserType === USER_ROLES.PARTICIPANT) {
        return {
            id: payload.ChatAppId,
            email: payload.Email,
            username: payload.FirstName ?? payload.LastName,
            nickName: payload.FirstName ?? payload.LastName,
            picture: profilePhotoSetter(payload?.OrganizationLogo),
            emailVerified: true,
            role: payload.UserType,
            accessToken: payload.AccessToken,
            loginType: payload.UserType,
            userId: payload.OrganizationId,
            claimedOnPortal: payload.ClaimedOnPortal,
            spDentistId: payload.Id,
            // lookupMultiOfferings: payload.LookupMultiOfferings,
            longitude: payload.Longitude,
            latitude: payload.Latitude,
            clientId: payload.ChatAppId,
            bookingAppId: payload.BookingAppId,
            expiresIn: payload.ExpiresIn
        }
    } else if (payload.UserType === USER_ROLES.ADMIN) {
        return {
            id: payload.Id,
            username: payload.Name,
            nickName: payload.Name,
            picture: profilePhotoSetter(payload?.ProfileImage),
            emailVerified: true,
            role: payload.UserType,
            accessToken: payload.AccessToken,
            loginType: payload.UserType,
            email: payload.Email,
            expiresIn: payload.ExpiresIn
        }
    }
    else {
        return {
            id: payload.Id,
            email: payload.Email,
            username: payload.FirstName,
            nickName: payload.FirstName,
            picture: profilePhotoSetter(payload?.ProfileImage),
            emailVerified: true,
            mobileNumber: payload.MobileNumber,
            location: payload.Location,
            regularDentist: payload.RegularDentist,
            address: payload.Address,
            role: 2,
            accessToken: payload.AccessToken,
            loginType: payload.UserType,
            longitude: payload.Longitude,
            latitude: payload.Latitude,
            expiresIn: payload.ExpiresIn
        }
    }
}
  