import { useLocalization } from "@progress/kendo-react-intl";
import { useCallback } from "react";

interface MessageProps {
    messageKey: string;
    defaultMessage: string;
    isHtml?: boolean;
    args?: string[],
}

export const Message = (props: MessageProps) => {
    const localization = useLocalization();

    const renderMessage = useCallback(() => {
        let text = localization.toLanguageString(props.messageKey, props.defaultMessage);
        if (props.args && props.args.length > 0) {
            text = formatString(text, props.args);
        }
        return text
    }, [localization, props.messageKey, props.defaultMessage, props.args])

    return (
        props.isHtml ? <span dangerouslySetInnerHTML={{ __html: renderMessage() }}>
        </span> :

            <span style={{ display: "block" }}>
                {localization.toLanguageString(props.messageKey, props.defaultMessage)}
            </span>
    );
};

export const formatString = (string: any, ...args: any) => {
    return string.replace(/{(\d+)}/g, (match: any, index: any) =>
        args[index] != null ? args[index] : match,
    );
};