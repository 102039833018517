import React, { memo, useEffect, useState } from 'react';
import './index.scss';

interface CardProps {
  title?: any;
  content?: any;
  toggle?: boolean;
  setToggle?: any;
}
const AccordionItem = ({ title, content, isOpen, toggle }: any) => {
  
  return (
    <div className="border-b border-gray-200">
      <h2>
        <button
          onClick={() =>toggle()}
          className="flex items-center justify-between w-full p-4 font-medium text-left hover:bg-gray-100 focus:outline-none"
          aria-expanded={isOpen}
        >
          <span>{title}</span>
          <svg
            className={`w-3 h-3 transition-transform duration-200 ${isOpen ? '' : 'rotate-180'}`}
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 10 6"
          >
            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5 5 1 1 5" />
          </svg>
        </button>
      </h2>
      {isOpen && (
        <div className="border-t border-gray-200 ease-in duration-300">
          {content}
        </div>
      )}
    </div>
  );
};

const Card = ({ title, content, toggle, setToggle }: CardProps) => {

  return (
    <div className="treatment-card-container">
      <div className="mx-auto">
        <AccordionItem
          key={1}
          title={title}
          content={content}
          isOpen={toggle}
          toggle={setToggle}
        />
      </div>
    </div>
  );
};

export default memo(Card);
