import * as React from "react";
import { DeSvg, UsSvg } from "../components/img";
import { DropDownList, DropDownListChangeEvent } from "@progress/kendo-react-dropdowns";
import './index.scss';
import { useAppDispatch, useAppSelector } from "../store/hooks";
import { chageSelectedLng } from "../views/bookingDataSlice";
import { RootState } from "../store/store";
import { setCookiesValue } from "../core/utils";

const langList = [
    { key: 'de_ch', name: 'Dutch', img: DeSvg },
    { key: 'en_us', name: 'English', img: UsSvg }
];

export const Chooser = () => {
    const dispatch = useAppDispatch();
    const selectedLng = useAppSelector((state: RootState) => state.bookingData.selectedLng);

    const onLngChange = (e: DropDownListChangeEvent) => {
        const selectedValue: "en_us" | "de_ch" = e.value.key;
        if (selectedValue === "en_us")
            setCookiesValue('globleLang', "En_Us");
        else
            setCookiesValue('globleLang', "De_ch");
        dispatch(chageSelectedLng(selectedValue));
    }

    const ValueRender = (element: React.ReactElement<HTMLSpanElement>, value: any) => {
        if (!value) return element;

        const children = [
            <div key={value.key} className="flex flex-row gap-2 ">
                <img src={value.img} className="w-[25px]" />
                <div className="drop-value hidden sm:block">{value.name}</div>
            </div>
        ];
        return React.cloneElement(element, { ...element.props }, children);
    };


    const ItemRender = (li: any, itemProps: any) => {
        let { dataItem } = itemProps;
        const index = itemProps.index;
        if (!dataItem) dataItem = itemProps;
        const itemChildren = <div className="flex flex-row gap-2 drop-value items-center justify-center">
            <div key={index} className="flex items-center justify-center"><img src={dataItem.img} className="w-[25px]" /></div><div className=' hidden sm:block'>{li.props.children}</div>
        </div>

        return React.cloneElement(li, li.props, itemChildren);
    }

    const defaultValueRender = () => {
        const defaultLng = langList.find(lang => lang.key === selectedLng)
        if (defaultLng)
            return defaultLng
        else
            return langList[0]
    }

    return (
        <DropDownList
            itemRender={ItemRender}
            valueRender={ValueRender}
            data={langList}
            defaultValue={defaultValueRender()}
            textField="name"
            dataItemKey="key"
            onChange={onLngChange}
        />
    );
};