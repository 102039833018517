import LoginForm from "../loginForm";
import SocialMediaLogin from "../socialMediaLogin";

const PatientTabView = ({
  localization,
  setLoadingLogin,
  loggedUserFetching,
  selected,
  handleSubmit
}: any) => {

  return (
    <div className="p-4">
      <SocialMediaLogin setLoadingLogin={setLoadingLogin} overlayLoading={true}/>
      <div className="text-lg flex items-center font-normal justify-center p-5">
        {localization.toLanguageString("login_module_or", "or")}
      </div>
      <LoginForm
        handleSubmit={handleSubmit}
        localization={localization}
        loggedUserFetching={loggedUserFetching}
        selected={selected}
        reset={true}
      />
    </div>
  );
};

export default PatientTabView;
